// @flow
import {type IInterval, validateInterval} from "@vimana/lib-timeline"

import {type Validator, shapeOf, optional, validateString, validateObject} from "vimana-types"

/**
 * An event at a plant.
 */
export type ICalendarEvent = {
  id?: void | CalendarEventID,
  sourceId?: void | CalendarEventSourceID,
  type: CalendarEventType,
  payload: CalendarEventPayload,
  interval: IInterval
}

/**
 * An ID for an event.
 */
export type CalendarEventID = string

/**
 * The id of the CalendarEventConfig that spawned the event.
 */
export type CalendarEventSourceID = string

/**
 * The type for a calendar event, e.g. "Shift".
 */
export type CalendarEventType = string

/**
 * The payload for a calendar event.
 */
export type CalendarEventPayload = Object

/**
 * Validate the UUID for an event.
 */
export function validateCalendarEventID(input: string): CalendarEventSourceID {
  return validateString(input)
}

/**
 * Validate the UUID for an event source.
 */
export function validateCalendarEventSourceID(input: string): CalendarEventSourceID {
  return validateString(input)
}

/**
 * Validate an event type.
 */
export function validateCalendarEventType(input: string): CalendarEventType {
  return validateString(input)
}

/**
 * Validate an event payload.
 */
export function validateCalendarEventPayload(input: Object): CalendarEventPayload {
  return validateObject(input)
}

/**
 * Validate an CalendarEvent.
 */
export const validateCalendarEvent: Validator<ICalendarEvent> = shapeOf({
  id: optional(validateCalendarEventID),
  type: validateCalendarEventType,
  sourceId: optional(validateCalendarEventSourceID),
  payload: validateCalendarEventPayload,
  interval: validateInterval
})
