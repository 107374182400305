// @flow

export type RealtimeConnectionError = Error & {
  code: number
}

/**
 * Returns (but does not throw) a RealtimeConnectionError.
 *
 * @example
 * throw makeRealtimeConnectionError("Something went wrong");
 */
export function makeRealtimeConnectionError(
  message: string,
  code: number = 0,
  name: string = "RealtimeConnectionError"
) {
  const error = new Error(message)
  error.name = name
  ;(error: $FlowFixMe).code = code

  if (typeof Error.captureStackTrace === "function") {
    Error.captureStackTrace(error, makeRealtimeConnectionError)
  }
  return error
}
