// @flow

import {DateTime} from "luxon"
import {
  type Validator,
  type HoursMinutes,
  type Weekday,
  optional,
  validateHoursMinutes,
  validateWeekday,
  validateString,
  shapeOf,
  arrayOf,
  intersect,
  invariant
} from "vimana-types"
import {type IInterval, isIntervalOverlapping} from "@vimana/lib-timeline"
import {getLuxonWeekday} from "../utils"
import {validateCalendarEventType, validateCalendarEventPayload, type CalendarEventType} from "./CalendarEvent"

const EVERY_DAY = ["MONDAY", "TUESDAY", "WEDNESDAY", "THURSDAY", "FRIDAY", "SATURDAY", "SUNDAY"]

/**
 * Represents a scheduled event.
 */
export type ICalendarEventConfig = {
  id?: void | CalendarEventConfigID,
  type: CalendarEventType,
  from: HoursMinutes,
  to: HoursMinutes,
  occursWeekly: CalendarEventConfigOccursWeekly,
  payload: Object
}

/**
 * The ID for a scheduled event.
 */
export type CalendarEventConfigID = string

/**
 * The days of the week this event occurs on.
 */
export type CalendarEventConfigOccursWeekly = Array<Weekday>

/**
 * Validates a Calendar Event ID.
 */
export const validateCalendarEventConfigID: Validator<CalendarEventConfigID> = validateString

/**
 * Validates a list of week days this event occurs on.
 */
export const validateCalendarEventConfigOccursWeekly: Validator<CalendarEventConfigOccursWeekly> = intersect(
  input => invariant(Array.isArray(input) && input.length > 0, "Must select at least one day."),
  arrayOf(validateWeekday)
)

/**
 * Validates a Calendar Event.
 */
export const validateCalendarEventConfig: Validator<ICalendarEventConfig> = shapeOf({
  id: optional(validateCalendarEventConfigID),
  type: validateCalendarEventType,
  from: validateHoursMinutes,
  to: validateHoursMinutes,
  payload: validateCalendarEventPayload,
  occursWeekly: validateCalendarEventConfigOccursWeekly
})

/**
 * Validates Calendar Event shift configs to span the whole day everyday.
 */

const throwShiftErrorForDay = day => {
  const error = new Error(`Invalid calendar event config for ${day}, Shifts should span across the day`)
  error.name = "InvariantViolation"
  throw error
}

const throwShiftError = () => {
  const error = new Error(`Invalid calendar event config, No shift defined`)
  error.name = "InvariantViolation"
  throw error
}

const doesIntervalSpanDay = intervals => {
  if (intervals.length === 1) {
    const interval = intervals[0]
    if (interval.from === interval.to) {
      return true
    }
  }
  return false
}

const getToValue = (mergedInterval, nextInterval, from) => {
  const doesNextShiftGoTillNextDay = nextInterval.to <= from
  if (doesNextShiftGoTillNextDay) {
    // Check for values where the end of interval goes to the next day. So values like 08:00 -> 16:00 and 16:00 -> 08:00 next day
    return nextInterval.to
  } else {
    return mergedInterval.to > nextInterval.to ? mergedInterval.to : nextInterval.to
  }
}

const mergeDupedIntervals = intervals => {
  intervals.sort((a, b) => {
    // string such as "06:00", "14:30" sort
    if (a.from > b.from) {
      return 1
    } else if (a.from < b.from) {
      return -1
    }
    return 0
  })

  const mergedIntervals = [intervals[0]]
  for (let i = 1; i < intervals.length; i++) {
    const mergedInterval = mergedIntervals[mergedIntervals.length - 1]
    const nextInterval = intervals[i]
    if (mergedInterval.from === mergedInterval.to) {
      // Shift spans across 24 hours
      break
    }
    const isOverlappingInterval = mergedInterval.to >= nextInterval.from
    if (isOverlappingInterval) {
      const {from} = mergedInterval
      const to = getToValue(mergedInterval, nextInterval, from)
      mergedIntervals[mergedIntervals.length - 1] = {from, to}
    } else {
      // Found non overlapping shifts so break early as shifts don't span 24 hours
      mergedIntervals[mergedIntervals.length] = nextInterval
      break
    }
  }
  return mergedIntervals
}

const nextDay = day => {
  const dayIndex = EVERY_DAY.findIndex(d => d === day)
  const nextDayIndex = (dayIndex + 1) % 7

  return EVERY_DAY[nextDayIndex]
}

export const validateCalendarEventConfigs: Validator<ICalendarEventConfig> = input => {
  const shiftEvents = input.filter(elem => elem.type.toLowerCase() === "shift")

  const noShiftsDefined = !shiftEvents.length
  if (noShiftsDefined) {
    throwShiftError()
  }

  const midnight = "00:00"
  const dayMap = {}
  EVERY_DAY.forEach(day => {
    dayMap[day] = []
  })

  shiftEvents.forEach(elem => {
    elem.occursWeekly.forEach(day => {
      const interval = {from: elem.from, to: elem.to}
      // At this point from and to are strings with fixed length
      // So, time comparison is same as string comparison
      if (elem.from >= elem.to && elem.to !== midnight) {
        dayMap[day].push({...interval, to: midnight})
        dayMap[nextDay(day)].push({...interval, from: midnight})
        return
      }
      dayMap[day].push(interval)
    })
  })

  Object.keys(dayMap).forEach(day => {
    const intervals = dayMap[day]
    const noShiftDefinedForDay = !intervals.length
    if (noShiftDefinedForDay) {
      throwShiftErrorForDay(day)
    }

    const mergedIntervals = mergeDupedIntervals(intervals) // merge intervals to give back a single interval spanning 24 hours
    if (!doesIntervalSpanDay(mergedIntervals)) {
      throwShiftErrorForDay(day)
    }
  })
  return input
}

/**
 * Converts HoursMinutes (e.g. "12:34") to a Luxon time object.
 */
export const hoursMinutesToLuxonTime = (input: HoursMinutes) => {
  const parts = input.split(":")
  const hour = parseInt(parts[0], 10)
  const minute = parseInt(parts[1], 10)
  return {hour, minute, second: 0, millisecond: 0}
}

/**
 * Get the duration of an interval in milliseconds.
 */
export const getIntervalDuration = (input: IInterval) => input.endMillis - input.startMillis

/**
 * Determines whether the given event occurs on the given weekday.
 */
export const eventConfigAppliesToWeekday = (config: ICalendarEventConfig, weekday: Weekday): boolean =>
  config.occursWeekly.length === 0 || config.occursWeekly.includes(weekday)

/**
 * Does the given event config span midnight?
 */
export const eventConfigSpansMidnight = (config: ICalendarEventConfig): boolean => {
  const start = hoursMinutesToLuxonTime(config.from)
  const end = hoursMinutesToLuxonTime(config.to)
  return start.hour > end.hour || (start.hour === end.hour && start.minute >= end.minute)
}

const plantTimeBeforeConfigStartTime = ({plantTime, start}) =>
  plantTime.hour < start.hour || (plantTime.hour === start.hour && plantTime.minute < start.minute)

const plantTimeAfterConfigEndTime = ({plantTime, end}) =>
  plantTime.hour > end.hour || (plantTime.hour === end.hour && plantTime.minute >= end.minute)

const notSpansMidnightAndPlantTimeBeforeConfigStartTime = args => {
  const {spansMidnight} = args
  return !spansMidnight || plantTimeBeforeConfigStartTime(args)
}

const eventConfigNotApplyForPrevDay = ({config, plantTime}) =>
  !eventConfigAppliesToWeekday(config, getLuxonWeekday(plantTime.minus({days: 1})))

const notSpansMidnightAndPlantTimeAfterConfigEndTime = args => {
  const {spansMidnight} = args
  return !spansMidnight || plantTimeAfterConfigEndTime(args)
}

const eventConfigNotApplyForToday = ({config, plantTime}) =>
  !eventConfigAppliesToWeekday(config, getLuxonWeekday(plantTime))

const doesEventConfigApplyForTodayOrPrevDay = args =>
  eventConfigNotApplyForToday(args) &&
  (notSpansMidnightAndPlantTimeAfterConfigEndTime(args) || eventConfigNotApplyForPrevDay(args))
// eventConfigNotApplyForPrevDay :-
// we're before the start of the event, but this could be
// an event which still applies from the previous occurrence.
const doesEventConfigApplyForPrevDay = args =>
  notSpansMidnightAndPlantTimeBeforeConfigStartTime(args) &&
  (plantTimeAfterConfigEndTime(args) || eventConfigNotApplyForPrevDay(args))

/**
 * Determines whether this event  config applies to the given Luxon DateTime.
 */
export const eventConfigAppliesToDateTime = (config: ICalendarEventConfig, plantTime: DateTime): boolean => {
  const start = hoursMinutesToLuxonTime(config.from)
  const end = hoursMinutesToLuxonTime(config.to)
  const spansMidnight = eventConfigSpansMidnight(config)
  const args = {config, plantTime, start, end, spansMidnight}

  if (doesEventConfigApplyForTodayOrPrevDay(args)) {
    return false
  }
  if (spansMidnight) {
    if (doesEventConfigApplyForPrevDay(args)) {
      return false
    }
  } else if (plantTimeBeforeConfigStartTime(args) || plantTimeAfterConfigEndTime(args)) {
    return false
  }

  return true
}

/**
 * Return an interval object representing the time range
 * for an event config at a given start date.
 */
export const getEventIntervalFromConfig = (config: ICalendarEventConfig, dayStart: DateTime) => {
  const start = hoursMinutesToLuxonTime(config.from)
  const end = hoursMinutesToLuxonTime(config.to)
  const spansMidnight = eventConfigSpansMidnight(config)

  if (spansMidnight) {
    return {
      startMillis: dayStart.set(start).valueOf(),
      endMillis: dayStart.set(end).plus({days: 1}).valueOf()
    }
  }
  return {
    startMillis: dayStart.set(start).valueOf(),
    endMillis: dayStart.set(end).valueOf()
  }
}

/**
 * Get the duration of the given event config in milliseconds.
 */
export const getEventDurationFromConfig = (config: ICalendarEventConfig) =>
  getIntervalDuration(getEventIntervalFromConfig(config, DateTime.local()))

/**
 * Turn the event configuration into a real event.
 */
export const getEventFromConfig = (config: ICalendarEventConfig, dayStart: DateTime) => {
  const {id: sourceId, type, payload} = config
  const interval = getEventIntervalFromConfig(config, dayStart)
  return {sourceId, type, payload, interval}
}

/**
 * Given an event config and a date time, return the next occurrence of the event.
 */
export const getNextEventFromConfig = (config: ICalendarEventConfig, plantTime: DateTime) => {
  if (config.occursWeekly.length === 0) {
    return
  }

  let clock = plantTime

  if (eventConfigAppliesToDateTime(config, clock)) {
    // the event is active right now, but we need to check whether
    // it started today or yesterday.

    const spansMidnight = eventConfigSpansMidnight(config)

    if (spansMidnight && clock.valueOf() < plantTime.set(hoursMinutesToLuxonTime(config.from)).valueOf()) {
      return getEventFromConfig(config, clock.minus({days: 1}).startOf("day"))
    }

    return getEventFromConfig(config, clock.startOf("day"))
  }

  if (eventConfigAppliesToWeekday(config, getLuxonWeekday(clock))) {
    // applies to today, but are we before or after the end?
    const spansMidnight = eventConfigSpansMidnight(config)

    if (!spansMidnight && clock.valueOf() > plantTime.set(hoursMinutesToLuxonTime(config.from)).valueOf()) {
      // we are after the end of the event.
      clock = clock.plus({days: 1})
    }
  }

  while (!eventConfigAppliesToWeekday(config, getLuxonWeekday(clock))) {
    clock = clock.plus({days: 1})
  }

  return getEventFromConfig(config, clock.startOf("day"))
}

/**
 * Given an event config, return all occurrences of the event between the given start and end times.
 * Note: This function does not use an IInterval object because we need to use plant time.
 */
export const getEventOccurrencesBetween = (config: ICalendarEventConfig, start: DateTime, end: DateTime) => {
  let clock = start
  const interval = {
    startMillis: start.valueOf(),
    endMillis: end.valueOf()
  }
  const events = []
  while (clock.valueOf() <= interval.endMillis) {
    const event = getNextEventFromConfig(config, clock)
    if (!event) {
      break
    }
    if (event.interval.endMillis !== interval.startMillis && !isIntervalOverlapping(event.interval, interval)) {
      break
    }
    events.push(event)
    const duration = event.interval.endMillis - event.interval.startMillis
    clock = DateTime.fromMillis(event.interval.endMillis + duration - 1, {
      zone: start.zoneName
    })
  }

  return events
}
