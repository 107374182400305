// @flow
import {type Validator, validateString, validateNumber, shapeOf, invariant} from "vimana-types"
import {DateTime} from "luxon"

/**
 * Represents the timezone of a Plant.
 */
export type ITimezone = {
  name: TimezoneName,
  currentOffsetMillis: TimezoneUTCOffset
}

/**
 * The standardized ID for a timezone, e.g. `Africa/Abidjan`
 */
export type TimezoneName = string

/**
 * The offset of a timezone from UTC in milliseconds.
 */
export type TimezoneUTCOffset = number

/**
 * Validates a timezone name.
 */
export function validateTimezoneName(input: string): TimezoneName {
  validateString(input)
  invariant(DateTime.local().setZone(input).isValid, "Must be a valid timezone name.")
  return input
}

/**
 * Validates a timezone offset.
 */
export function validateTimezoneUTCOffset(input: number): TimezoneUTCOffset {
  return validateNumber(input)
}

/**
 * Validates a timezone.
 */
export const validateTimezone: Validator<ITimezone> = shapeOf({
  name: validateTimezoneName,
  currentOffsetMillis: validateTimezoneUTCOffset
})

/**
 * Get the UTC offset for a timezone with the given name and time.
 */
export function getTimezoneOffsetMillis(name: TimezoneName, timeInUTC: number = new Date().getTime()) {
  return DateTime.fromSeconds(timeInUTC / 1000).setZone(name).offset * 60000
}
