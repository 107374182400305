// @flow
import {type IInterval, validateInterval} from "@vimana/lib-timeline"

import {
  type Validator,
  type DurationMS,
  type DeviceUUID,
  shapeOf,
  arrayOf,
  optional,
  validateString,
  validateDurationMS
} from "vimana-types"
import {type IAgenda, validateAgenda} from "../Agenda"

/**
 * Schedules determine event and classification time information for a device,
 * group of devices or an entire plant.
 */
export type ISchedule = {
  id?: void | ScheduleID,
  name: ScheduleName,
  interval: IInterval,
  workdayOffset: DurationMS,
  agendas: IAgenda[]
}

/**
 * The ID of a schedule
 */
export type ScheduleID = string

/**
 * The name of a schedule.
 */
export type ScheduleName = string

/**
 * Validates a Schedule ID.
 */
export function validateScheduleID(input: string): ScheduleID {
  return validateString(input)
}

/**
 * Validates a Schedule Name.
 */
export function validateScheduleName(input: string): ScheduleName {
  return validateString(input)
}

/**
 * Validates a Schedule.
 */
export const validateSchedule: Validator<ISchedule> = shapeOf({
  id: optional(validateScheduleID),
  name: validateScheduleName,
  interval: validateInterval,
  workdayOffset: validateDurationMS,
  agendas: arrayOf(validateAgenda)
})

/**
 * Get the plant level agenda within a schedule.
 * @throws {Error} if no plant level agenda is found.
 */
export function getPlantAgenda(schedule: ISchedule): IAgenda {
  for (const agenda of schedule.agendas) {
    if (agenda.devices.length === 0) {
      return agenda
    }
  }
  throw new Error("Invalid schedule, no plant level agenda specified")
}

/**
 * Get the agenda for a specific device within a schedule.
 * Returns the plant level agenda if no agenda exists for the device.
 */
export function getDeviceAgenda(schedule: ISchedule, device: DeviceUUID): IAgenda {
  for (const agenda of schedule.agendas) {
    if (agenda.devices.includes(device)) {
      return agenda
    }
  }
  return getPlantAgenda(schedule)
}
