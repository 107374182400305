// @flow

import {type PlantName} from "vimana-types"
import {type RealtimeSubscriberID, type RealtimeSubscriber, type RealtimeSubscriptionParams} from "./RealtimeSubscriber"
import {version} from "../package.json"
import {makeRealtimeConnectionError} from "./RealtimeConnectionError"

const errorCodeDescriptions = {
  "1000": "Normal closure, meaning that the purpose for which the connection was established has been fulfilled.",
  "1001": 'An endpoint is "going away", such as a server going down or a browser having navigated away from a page.',
  "1002": "An endpoint is terminating the connection due to a protocol error",
  "1003":
    "An endpoint is terminating the connection because it has received a type of data it cannot accept (e.g., an endpoint that understands only text data MAY send this if it receives a binary message).",
  "1005": "No status code was actually present.",
  "1006": "The connection was closed abnormally, e.g., without sending or receiving a Close control frame",
  "1007":
    "An endpoint is terminating the connection because it has received data within a message that was not consistent with the type of the message (e.g., non-UTF-8 [http://tools.ietf.org/html/rfc3629] data within a text message).",
  "1008":
    'An endpoint is terminating the connection because it has received a message that "violates its policy". This reason is given either if there is no other sutible reason, or if there is a need to hide specific details about the policy.',
  "1009":
    "An endpoint is terminating the connection because it has received a message that is too big for it to process.",
  "1010":
    "An endpoint (client) is terminating the connection because it has expected the server to negotiate one or more extension, but the server didn't return them in the response message of the WebSocket handshake.",
  "1011":
    "A server is terminating the connection because it encountered an unexpected condition that prevented it from fulfilling the request.",
  "1015":
    "The connection was closed due to a failure to perform a TLS handshake (e.g., the server certificate can't be verified)."
}

/**
 * The configuration for a `RealtimeConnection`.
 */
export type RealtimeConnectionConfig = {
  /**
   * The credentials used to authenticate with the server.
   */
  +credentials: RealtimeConnectionCredentials,
  /**
   * The function to invoke when the connection is established.
   */
  +onOpen?: void | (RealtimeConnection => void),
  /**
   * The function to invoke when the server sends a command to the client.
   */
  +onCommand?: void | ((CommandName, void | CommandPayload) => void),
  /**
   * The function to invoke when the connection closes normally
   */
  +onClose?: void | (() => void),
  /**
   * The function to invoke when the connection closes in error.
   */
  +onError?: void | (Error => void)
}

/**
 * The name of a server-sent command.
 */
export type CommandName = string

/**
 * The payload of a server-sent command.
 */
export type CommandPayload = Object

/**
 * The credentials used to authenticate with the server.
 */
export type RealtimeConnectionCredentials = {
  /**
   * The encoded JWT.
   */
  +token: string,
  /**
   * The name of the plant.
   */
  +plantName: PlantName
}

/**
 * A real-time connection to Vimana, provides a `subscribe()` method to
 * allow subscriptions to events.
 *
 * @example
 *
 *   import { RealtimeConnection } from "@vimana/lib-realtime";
 *
 *   const websocket = new WebSocket("wss://example.com");
 *
 *   const connection = new RealtimeConnection(websocket, {
 *     credentials: {
 *       token: "your_token_here",
 *       plantName: "demo"
 *     },
 *     onOpen: () => console.log("Connection open"),
 *     onCommand: (commandName, payload) =>
 *       console.log("Received command", commandName, payload),
 *     onClose: () => console.log("Connection closed gracefully"),
 *     onError: error => console.error(error)
 *   });
 *
 *   const subscription = connection.subscribe(["something", [1, 2, 3]], {
 *     next: value => console.log("Received value", value),
 *     complete: () => console.log("Subscription complete"),
 *     error: error => console.error("Subscription error", error)
 *   });
 *
 *   // Later
 *   setTimeout(() => subscription.unsubscribe(), 10000);
 *
 */
export class RealtimeConnection {
  /**
   * The number of commands we've sent so far.
   * This is used to assign IDs to subscribers.
   */
  commandCounter: number = 0

  /**
   * Indicates whether or not the connection is established.
   */
  // eslint-disable-next-line
  isConnected: boolean = false;

  /**
   * The websocket used for transport.
   */
  +websocket: WebSocket

  /**
   * The credentials used to authenticate the connection.
   */
  +credentials: RealtimeConnectionCredentials

  /**
   * The function to invoke when the connection is established.
   */
  onOpen: void | (RealtimeConnection => void)

  /**
   * The function to invoke when the server sends a command to the client.
   */
  onCommand: void | ((CommandName, void | CommandPayload) => void)

  /**
   * The function to invoke when the connection closes normally
   */
  onClose: void | (() => void)

  /**
   * The function to invoke when the connection closes in error.
   */
  onError: void | (Error => void)

  /**
   * A map of subscriber ids to their associated handlers.
   * @private
   */
  _subscribers: Map<RealtimeSubscriberID, RealtimeSubscriber<any>> = new Map()

  /**
   * A queue of serialized commands to send when the handshake completes.
   * This is used to allow `.subscribe()` without waiting for the
   * handshake.
   * @private
   */
  _queue: Array<string> = []

  /**
   * Initialize the connection.
   */
  constructor(websocket: WebSocket, config: RealtimeConnectionConfig) {
    this.websocket = websocket
    this.credentials = config.credentials
    this.onOpen = config.onOpen
    this.onCommand = config.onCommand
    this.onClose = config.onClose
    this.onError = config.onError

    /* eslint no-param-reassign: ["error", { "props": true, "ignorePropertyModificationsFor": ["websocket"] }] */
    websocket.onopen = this.handleOpen.bind(this)
    websocket.onmessage = this.handleMessage.bind(this)
    websocket.onclose = this.handleClose.bind(this)
    websocket.onerror = this.handleError.bind(this)
  }

  /**
   * Invoked when the websocket connection is established.
   */
  handleOpen = () => {
    const {websocket} = this
    const {token: jwt, plantName} = this.credentials
    websocket.send(JSON.stringify([0, "handshake", {jwt, plantName, version}]))
  }

  /**
   * Invoked when a message is received over the websocket.
   */
  // eslint-disable-next-line sonarjs/cognitive-complexity
  handleMessage(event: MessageEvent) {
    const {data} = event
    if (typeof data !== "string") {
      console.warn("Received unknown message data:", data)
      return
    }

    const [subscriberId, command, params] = JSON.parse(data)
    if (subscriberId === -1) {
      this.handleCommand(command, params)
      return
    } else if (subscriberId === 0) {
      if (command === "error") {
        this.handleError()
      } else {
        // this is a handshake response.
        this.isConnected = true
        while (this._queue.length > 0) {
          this.websocket.send(this._queue.shift())
        }
        if (typeof this.onOpen === "function") {
          this.onOpen(this)
        }
      }
      return
    }
    const subscriber = this._subscribers.get(subscriberId)
    if (subscriber) {
      let value = params
      if (command === "error") {
        value = new Error((params && params.message) || "Unknown Realtime Error")
        value.name = (params && params.name) || "RealtimeServerError"
        this._subscribers.delete(subscriberId)
      } else if (command === "complete") {
        this._subscribers.delete(subscriberId)
      }
      if (typeof subscriber[command] === "function") {
        // $FlowIgnore
        subscriber[command](value)
      } else {
        console.warn("Received unknown command", {
          subscriberId,
          command,
          value
        })
      }
    } else if (command !== "complete") {
      console.warn("Received response for unknown subscriber", {
        subscriberId,
        command,
        params
      })
    }
  }

  /**
   * Handle a command received from the server.
   */
  handleCommand(command: string, params?: Object) {
    if (command === "error") {
      const error = makeRealtimeConnectionError(
        (params && params.message) || "Unknown Realtime Error",
        500,
        (params && params.name) || "RealtimeServerError"
      )
      this.isConnected = false
      for (const [subscriberId, subscriber] of this._subscribers) {
        this._subscribers.delete(subscriberId)
        subscriber.error(error)
      }
      if (typeof this.onError === "function") {
        this.onError(error)
      }
      this.websocket.close()
    } else if (typeof this.onCommand === "function") {
      this.onCommand(command, params)
    } else {
      console.warn("Received command but we don't know what to do with it:", command, params)
    }
  }

  /**
   * Invoked when the connection closes normally
   */
  handleClose(event: CloseEvent) {
    this.isConnected = false
    if (event.code === 1000) {
      // Normal close event
      for (const [subscriberId, subscriber] of this._subscribers) {
        this._subscribers.delete(subscriberId)
        subscriber.complete()
      }
      if (typeof this.onClose === "function") {
        this.onClose()
      }
    } else {
      const error = makeRealtimeConnectionError(
        errorCodeDescriptions[event.code] || "Unknown WebSocket Error",
        event.code
      )
      for (const [subscriberId, subscriber] of this._subscribers) {
        this._subscribers.delete(subscriberId)
        subscriber.error(error)
      }
      if (typeof this.onError === "function") {
        this.onError(error)
      }
    }
  }

  /**
   * Invoked when the connection closes in error.
   */
  handleError() {
    const error = makeRealtimeConnectionError("Realtime Connection Error", 500)
    this.isConnected = false
    for (const [subscriberId, subscriber] of this._subscribers) {
      this._subscribers.delete(subscriberId)
      subscriber.error(error)
    }
    if (typeof this.onError === "function") {
      this.onError(error)
    }
  }

  /**
   * Subscribe to a given topic.
   */
  subscribe<T>(params: RealtimeSubscriptionParams, subscriber: RealtimeSubscriber<T>) {
    this.commandCounter++
    const subscriberId = this.commandCounter
    this._subscribers.set(subscriberId, subscriber)
    const payload = JSON.stringify([subscriberId, "subscribe", params])
    if (this.isConnected) {
      this.websocket.send(payload)
    } else {
      this._queue.push(payload)
    }

    let closed = false

    const unsubscribe = () => {
      if (closed) {
        console.warn("Unsubscribe called more than once on subscription ", subscriberId)
      } else {
        closed = true
        const subscriberToUnsubscribe = this._subscribers.get(subscriberId)
        if (subscriberToUnsubscribe) {
          this.commandCounter++
          const commandId = this.commandCounter
          this._subscribers.delete(subscriberId)
          subscriberToUnsubscribe.complete()
          if (this.websocket.readyState === WebSocket.OPEN) {
            this.websocket.send(JSON.stringify([commandId, "unsubscribe", {id: subscriberId}]))
          }
        }
      }
    }

    return {
      get closed(): boolean {
        return closed
      },
      unsubscribe
    }
  }

  /**
   * Close the connection.
   */
  close() {
    if (this.isConnected) {
      this.isConnected = false
      if (this.websocket.readyState !== WebSocket.CLOSING && this.websocket.readyState !== WebSocket.CLOSED) {
        this.websocket.close()
      }
    }
  }
}
