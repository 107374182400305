// @flow

import {type DurationMS} from "vimana-types"
import invariant from "invariant"
import {
  RealtimeConnection,
  type RealtimeConnectionCredentials,
  type CommandName,
  type CommandPayload
} from "./RealtimeConnection"
import {RealtimeSubscriptionCache, type RealtimeSubscriptionCacheConfig} from "./RealtimeSubscriptionCache"

/**
 * A function which can create a websocket.
 */
export type GetWebSocket = () => WebSocket

/**
 * A function which can get the credentials for a connection.
 */
export type GetCredentials = () => RealtimeConnectionCredentials

/**
 * Configuration for a `RealtimeConnectionManager`.
 */
export type RealtimeConnectionManagerConfig = {
  /**
   * Create a websocket connection.
   */
  getWebSocket: GetWebSocket,

  /**
   * Gets the credentials used to authenticate a connection.
   */
  getCredentials: GetCredentials,

  /**
   * Invoked when a connection opens.
   */
  onOpen?: void | (RealtimeConnection => void),

  /**
   * Invoked when a connection receives a command.
   */
  onCommand?: void | ((CommandName, void | CommandPayload) => void),

  /**
   * Invoked when a connection closes normally.
   */
  onClose?: void | (() => void),

  /**
   * Invoked when a connection closes in error.
   */
  onError?: void | (Error => void),

  /**
   * Whether to reconnect even if the connection closes normally.
   */
  reconnectOnClose?: boolean,

  /**
   * The minimum number of milliseconds to wait before attempting
   * to reconnect a dropped connection.
   */
  minReconnectDelay?: DurationMS,

  /**
   * The maximum number of milliseconds to wait before attempting
   * to reconnect a dropped connection.
   */
  maxReconnectDelay?: DurationMS,

  /**
   * The factor by which to increase the reconnection delay with each
   * failed retry. A factor of `1` would be no increase, `2` would double
   * the timeout, etc.
   */
  reconnectBackoffFactor?: number,

  /**
   * The cache for the manager.
   */
  cache?: RealtimeSubscriptionCache | RealtimeSubscriptionCacheConfig
}

/**
 * Responsible for creating connections, and reconnecting when the connection drops.
 *
 * Invokes `onOpen` with a connection instance whenever a new connection is established.
 *
 * @example
 *
 *    import {RealtimeConnectionManager} from "@vimana/lib-realtime";
 *
 *    const manager = new RealtimeConnectionManager({
 *       getWebSocket: () => new WebSocket("wss://example.com/"),
 *       getCredentials: () => ({
 *         token: "abc123",
 *         plantName: "demo"
 *       }),
 *       onOpen: connection => connection.subscribe(["something", {id: 123}], {
 *          next: value => console.log(value),
 *          complete: () => console.log("done"),
 *          error: err => console.error(error)
 *       })
 *    });
 *
 */
export class RealtimeConnectionManager {
  /**
   * Create a websocket connection.
   */
  getWebSocket: GetWebSocket

  /**
   * Returns the credentials used to authenticate a connection.
   */
  getCredentials: GetCredentials

  /**
   * Invoked when a connection opens.
   */
  onOpen: void | (RealtimeConnection => void)

  /**
   * Invoked when a connection receives a command.
   */
  onCommand: void | ((CommandName, void | CommandPayload) => void)

  /**
   * Invoked when a connection closes normally.
   */
  onClose: void | (() => void)

  /**
   * Invoked when a connection closes in error.
   */
  onError: void | (Error => void)

  /**
   * The minimum number of milliseconds to wait before attempting
   * to reconnect a dropped connection.
   */
  minReconnectDelay: DurationMS = 250

  /**
   * The maximum number of milliseconds to wait before attempting
   * to reconnect a dropped connection.
   */
  maxReconnectDelay: DurationMS = 30000

  /**
   * The factor by which to increase the reconnection delay with each
   * failed retry. A factor of `1` would be no increase, `2` would double
   * the timeout, etc.
   */
  reconnectBackoffFactor: number = 1.5

  /**
   * Whether to reconnect even if the connection closes normally.
   */
  reconnectOnClose: boolean = false

  /**
   * The manager's subscription cache.
   */
  cache: RealtimeSubscriptionCache

  /**
   * The active `RealtimeConnection` (if one has been created).
   * @private
   */
  _activeConnection: void | RealtimeConnection

  /**
   * The number of milliseconds we waited before our last reconnection attempt.
   * @private
   */
  _lastBackoff: number = 0

  /**
   * The active reconnection timer.
   */
  _reconnectionTimer: void | TimeoutID

  /**
   * Determine whether the manager has a connected, active connection.
   */
  get isConnected(): boolean {
    const {_activeConnection} = this
    if (_activeConnection) {
      return _activeConnection.isConnected
    }
    return false
  }

  /**
   * Initialize the connection manager.
   */
  constructor(config: RealtimeConnectionManagerConfig) {
    this.getWebSocket = config.getWebSocket
    this.getCredentials = config.getCredentials

    this.onOpen = config.onOpen
    this.onCommand = config.onCommand
    this.onClose = config.onClose
    this.onError = config.onError

    if (typeof config.reconnectOnClose === "boolean") {
      this.reconnectOnClose = config.reconnectOnClose
    }

    if (typeof config.minReconnectDelay === "number") {
      this.minReconnectDelay = config.minReconnectDelay
    }
    if (typeof config.maxReconnectDelay === "number") {
      this.maxReconnectDelay = config.maxReconnectDelay
    }
    if (typeof config.reconnectBackoffFactor === "number") {
      this.reconnectBackoffFactor = config.reconnectBackoffFactor
    }

    if (config.cache instanceof RealtimeSubscriptionCache) {
      this.cache = config.cache
    } else {
      this.cache = new RealtimeSubscriptionCache(config.cache || {})
    }
  }

  /**
   * Get a connection, creating one if necessary.
   */
  getConnection() {
    if (this._activeConnection) {
      return this._activeConnection
    } else if (this._lastBackoff > 0) {
      // Cannot getConnection() while a reconnection attempt is in progress.
      // @todo should this be a warning or an error?
      return undefined
    }
    return this.createConnection()
  }

  /**
   * Create a connection.
   */
  createConnection(): RealtimeConnection {
    if (this._activeConnection) {
      throw new Error("Cannot createConnection() when we already have an active connection.")
    }
    const {getWebSocket, getCredentials} = this

    const credentials = getCredentials()

    this._activeConnection = new RealtimeConnection(getWebSocket(), {
      credentials,
      onOpen: this.handleConnectionOpen.bind(this),
      onCommand: this.handleConnectionCommand.bind(this),
      onClose: this.handleConnectionClose.bind(this),
      onError: this.handleConnectionError.bind(this)
    })
    return this._activeConnection
  }

  /**
   * Dispose of the manager and disconnect any active connections.
   * Event handlers will not be invoked.
   */
  dispose() {
    this.onOpen = undefined
    this.onCommand = undefined
    this.onClose = undefined
    this.onError = undefined
    if (this._reconnectionTimer !== undefined) {
      clearTimeout(this._reconnectionTimer)
      this._reconnectionTimer = undefined
    }
    if (this._activeConnection !== undefined) {
      this._activeConnection.close()
      this._activeConnection = undefined
    }
  }

  /**
   * Invoked when a connection opens.
   */
  handleConnectionOpen(connection: RealtimeConnection) {
    if (this._activeConnection !== connection) {
      // Ensure we set the active connection for scenarios where the
      // connection is opened synchronously (in tests).
      this._activeConnection = connection
    }
    this.cache.onConnection(connection)
    invariant(connection, "activeConnection must be present after open.")
    this._lastBackoff = 0
    if (this._reconnectionTimer !== undefined) {
      clearTimeout(this._reconnectionTimer)
      this._reconnectionTimer = undefined
    }
    if (typeof this.onOpen === "function") {
      this.onOpen(connection)
    }
  }

  /**
   * Invoked when a connection receives a server-sent command.
   */
  handleConnectionCommand(commandName: CommandName, payload?: CommandPayload) {
    if (typeof this.onCommand === "function") {
      this.onCommand(commandName, payload)
    }
  }

  /**
   * Invoked when a connection closes normally.
   */
  handleConnectionClose() {
    this._activeConnection = undefined
    if (this.reconnectOnClose) {
      const {minReconnectDelay, maxReconnectDelay, reconnectBackoffFactor, _lastBackoff} = this

      const backoff = Math.min(Math.max(minReconnectDelay, _lastBackoff * reconnectBackoffFactor), maxReconnectDelay)

      this._lastBackoff = backoff
      if (this._reconnectionTimer !== undefined) {
        clearTimeout(this._reconnectionTimer)
        this._reconnectionTimer = undefined
      }
      this._reconnectionTimer = setTimeout(() => {
        this._reconnectionTimer = undefined
        this.createConnection()
      }, backoff)
    }
    if (typeof this.onClose === "function") {
      this.onClose()
    }
  }

  /**
   * Invoked when a connection closes in error.
   */
  handleConnectionError(error: Error) {
    this._activeConnection = undefined
    if (typeof this.onError === "function") {
      this.onError(error)
    }

    const {minReconnectDelay, maxReconnectDelay, reconnectBackoffFactor, _lastBackoff} = this

    const backoff = Math.min(Math.max(minReconnectDelay, _lastBackoff * reconnectBackoffFactor), maxReconnectDelay)

    this._lastBackoff = backoff
    if (this._reconnectionTimer !== undefined) {
      clearTimeout(this._reconnectionTimer)
      this._reconnectionTimer = undefined
    }
    this._reconnectionTimer = setTimeout(() => {
      this._reconnectionTimer = undefined
      this.createConnection()
    }, backoff)
  }
}
