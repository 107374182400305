// @flow
import {validateString, validateBoolean, shapeOf, type Validator} from "../Common"

/**
 * Represents a possible device activity.
 */
export type IPlantActivity = {
  id: PlantActivityID,
  name: PlantActivityName,
  description: PlantActivityDescription,
  show: PlantActivityShouldShow
}

/**
 * The unique ID of an activity.
 */
export type PlantActivityID = string

/**
 * The human friendly name of an activity.
 */
export type PlantActivityName = string

/**
 * A short, text-only description of an activity.
 */
export type PlantActivityDescription = string

/**
 * Whether the activity should show.
 * @todo clarification
 */
export type PlantActivityShouldShow = boolean

/**
 * Get the plant activity with the given id
 * from an array of plant activities.
 * @throws {Error} if no such activity exists.
 */
export function getPlantActivity(activities: IPlantActivity[], id: PlantActivityID) {
  for (let i = 0; i < activities.length; i++) {
    const item = activities[i]
    if (item.id === id) {
      return item
    }
  }
  throw new Error(`Could not find a PlantActivity with id: ${id}`)
}

/**
 * Validate a Plant Activity ID.
 * @todo implementation
 */
export function validatePlantActivityID(input: string): PlantActivityID {
  return validateString(input)
}

/**
 * Validate a Plant Activity Name.
 * @todo implementation
 */
export function validatePlantActivityName(input: string): PlantActivityName {
  return validateString(input)
}
/**
 * Validate a Plant Activity Description.
 * @todo implementation
 */
export function validatePlantActivityDescription(input: string): PlantActivityDescription {
  return validateString(input)
}

/**
 * Validate a Plant Activity Should Show value.
 */
export function validatePlantActivityShouldShow(input: boolean): PlantActivityShouldShow {
  return validateBoolean(input)
}

/**
 * Validate a Plant Activity.
 */
export const validatePlantActivity: Validator<IPlantActivity> = shapeOf({
  id: validatePlantActivityID,
  name: validatePlantActivityName,
  description: validatePlantActivityDescription,
  show: validatePlantActivityShouldShow
})
