// @flow
import {type Validator, shapeOf, arrayOf, validateString} from "vimana-types"
import {type IInterval, validateInterval} from "@vimana/lib-timeline"
import {type ICalendarEvent, validateCalendarEvent} from "../CalendarEvent"

/**
 * Represents a working day at a plant.
 * Workdays consist of one or more events.
 */
export type IWorkday = {
  id: WorkdayID,
  interval: IInterval,
  events: ICalendarEvent[]
}

/**
 * The ID of a workday.
 * An ISO formatted Date, e.g. 2018-01-18
 */
export type WorkdayID = string

/**
 * Validates a WorkdayID
 */
export const validateWorkdayID: Validator<WorkdayID> = validateString

/**
 * Validate a Workday.
 */
export const validateWorkday: Validator<IWorkday> = shapeOf({
  id: validateWorkdayID,
  interval: validateInterval,
  events: arrayOf(validateCalendarEvent)
})
