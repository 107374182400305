// @flow

import {type DateTime} from "luxon"
import {type Weekday} from "vimana-types"

/**
 * Get the Weekday for a given Luxon DateTime object.
 */
export function getLuxonWeekday(dateTime: DateTime): Weekday {
  switch (dateTime.weekday) {
    case 1:
      return "MONDAY"
    case 2:
      return "TUESDAY"
    case 3:
      return "WEDNESDAY"
    case 4:
      return "THURSDAY"
    case 5:
      return "FRIDAY"
    case 6:
      return "SATURDAY"
    case 7:
      return "SUNDAY"
    // istanbul ignore next
    default:
      throw new Error("Impossible result from dateTime.weekday")
  }
}
