export function getContainingIntervalForWorkday(workday, interval) {
  const containingInterval = {
    startMillis: interval.startMillis,
    endMillis: interval.endMillis
  }

  if (workday.interval.startMillis < interval.startMillis || interval.startMillis === 0) {
    containingInterval.startMillis = workday.interval.startMillis
  }
  if (workday.interval.endMillis > interval.endMillis) {
    containingInterval.endMillis = workday.interval.endMillis
  }
  return containingInterval
}
