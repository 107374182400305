// @flow
import {
  type Validator,
  type DeviceUUID,
  shapeOf,
  arrayOf,
  optional,
  validateString,
  validateDeviceUUID
} from "vimana-types"
import {compareTimelineItemsByInterval} from "@vimana/lib-timeline"
import {type DateTime} from "luxon"
import {
  type ICalendarEvent,
  type ICalendarEventConfig,
  validateCalendarEventConfig,
  getEventOccurrencesBetween,
  validateCalendarEventConfigs
} from "../CalendarEvent"

/**
 * Agendas determine shift, classification and other event time information for
 * either an entire plant or 1 or or more specific devices.
 */
export type IAgenda = {
  id?: void | AgendaID,
  devices: DeviceUUID[],
  eventConfigs: ICalendarEventConfig[]
}

/**
 * The ID of an Agenda
 */
export type AgendaID = string

/**
 * The name of an Agenda.
 */
export type AgendaName = string

/**
 * Validates an Agenda ID.
 */
export function validateAgendaID(input: string): AgendaID {
  return validateString(input)
}

/**
 * Validates an Agenda.
 */
export const validateAgenda: Validator<IAgenda> = input => {
  shapeOf({
    id: optional(validateAgendaID),
    devices: arrayOf(validateDeviceUUID),
    eventConfigs: arrayOf(validateCalendarEventConfig)
  })(input)
  validateCalendarEventConfigs(input.eventConfigs)
  return input
}

/**
 * Return all event occurrences between the given start and end date times for a specific agenda.
 */
export function getAgendaEventOccurrencesBetween(agenda: IAgenda, start: DateTime, end: DateTime): ICalendarEvent[] {
  const eventConfigs = []
  for (const config of agenda.eventConfigs) {
    eventConfigs.push(...getEventOccurrencesBetween(config, start, end))
  }
  return eventConfigs.sort(compareTimelineItemsByInterval)
}
